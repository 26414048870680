// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-historie-js": () => import("../src/pages/historie.js" /* webpackChunkName: "component---src-pages-historie-js" */),
  "component---src-pages-hlasuj-js": () => import("../src/pages/hlasuj.js" /* webpackChunkName: "component---src-pages-hlasuj-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pravidla-webview-js": () => import("../src/pages/pravidla-webview.js" /* webpackChunkName: "component---src-pages-pravidla-webview-js" */),
  "component---src-pages-pravidla-js": () => import("../src/pages/pravidla.js" /* webpackChunkName: "component---src-pages-pravidla-js" */),
  "component---src-pages-talks-js": () => import("../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */)
}

